import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { tap } from "rxjs/operators";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class CustomersService {
  constructor(private http: HttpClient) {}
  create(quotesForm) {
    return this.http
      .post(environment.apiURL + "quotes/create", quotesForm)
      .pipe(
        tap((response: any) => {
          return response;
        })
      );
  }

  getCentralLocations(id) {
    return this.http.get(environment.apiURL + "disposallocation/getCentralLocation/"+id).pipe(
      tap((response: any) => {
        return response;
      })
    );
  }

  filterCentralLocations(id) {
    return this.http.post(environment.apiURL + "disposallocation/filterCentralLocations/",{"id":id}).pipe(
      tap((response: any) => {
        return response;
      })
    );
  }

  companyList() {
    return this.http.get(environment.apiURL + "company/list").pipe(
      tap((response: any) => {
        return response;
      })
    );
  }
  companyDetails(id) {
    return this.http.get(environment.apiURL + "company/" + id).pipe(
      tap((response: any) => {
        return response;
      })
    );
  }
  createCompany(data) {
    return this.http.post(environment.apiURL + "company/create", data).pipe(
      tap((response: any) => {
        return response;
      })
    );
  }
  updateCompany(data) {
    return this.http.post(environment.apiURL + "company/update", data).pipe(
      tap((response: any) => {
        return response;
      })
    );
  }
  deleteCompany(id) {
    return this.http.delete(environment.apiURL + "company/delete/" + id).pipe(
      tap((response: any) => {
        return response;
      })
    );
  }
  fetchQuotes() {
    return this.http.get(environment.apiURL + "quotes/list").pipe(
      tap((response: any) => {
        return response;
      })
    );
  }
  deleteQuotes(quotesID) {
    return this.http
      .delete(environment.apiURL + "quotes/delete/" + quotesID)
      .pipe(
        tap((response: any) => {
          return response;
        })
      );
  }
  updateQuotes(updateForm) {
    return this.http
      .post(environment.apiURL + "quotes/update", updateForm)
      .pipe(
        tap((response: any) => {
          return response;
        })
      );
  }
  wasteTypeList() {
    return this.http.get(environment.apiURL + "wastetype/list").pipe(
      tap((response: any) => {
        return response;
      })
    );
  }
  fetchDisposalLocation(data?: any) {
    if (!data || data == undefined || data == null) {
      data = {};
    }
    return this.http
      .post(environment.apiURL + "disposallocation/list", data)
      .pipe(
        tap((response: any) => {
          return response;
        })
      );
  }
}
