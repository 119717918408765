import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef,
} from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-view-job",
  templateUrl: "./view-job.component.html",
  styleUrls: ["./view-job.component.scss"],
})
export class ViewJobComponent implements OnInit {
  @Input("data") viewJob: any = {};
  @Input("pageIndex") pageIndex: any;
  @Output("closeEvents") closeEvents = new EventEmitter<any>();
  isView = false;
  locationList = [];
  allLocationList = [];
  slide = true;
  imageList = [];
  image: any;
  data: any = [];
  lat = 53.707952345979145;
  long = -1.2452939277947062;
  zoom = 8;
  showLoader = true;
  interimLocationList: any;
  viewData: any;
  previous;
  cols = [
    {
      header: "Address",
      field: "address",
    },
  ];
  cols1 = [
    {
      header: "Name",
      field: "certificate_img_File",
    },
  ];
  openChatWindow: boolean;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    if (this.viewJob && Object.keys(this.viewJob).length !== 0) {
      console.log("view job", this.viewJob);

      if (this.viewJob) {
        this.viewJob.isCompleted =
          this.viewJob.isCompleted == true ? "Yes" : "No";
        if (this.viewJob.disposeLocationDetails) {
          this.viewJob.disposeLocationDetails.totalCost = this.viewJob
            .disposeLocationDetails.totalCost
            ? parseFloat(this.viewJob.disposeLocationDetails.totalCost).toFixed(
                2
              ) + (this.viewJob.disposeLocationDetails.extras ? parseFloat(this.viewJob.disposeLocationDetails.extras.totalCost).toFixed(
                2
              ) : 0)
            : "";
          this.viewJob.disposeLocationDetails.travelCost = this.viewJob
            .disposeLocationDetails.travelCost
            ? parseFloat(
                this.viewJob.disposeLocationDetails.travelCost
              ).toFixed(2) + (this.viewJob.disposeLocationDetails.extras ? parseFloat(this.viewJob.disposeLocationDetails.extras.travelCost).toFixed(
                2
              ) : 0)
            : "";
          this.viewJob.disposeLocationDetails.driverCost = this.viewJob
            .disposeLocationDetails.driverCost
            ? parseFloat(
                this.viewJob.disposeLocationDetails.driverCost
              ).toFixed(2) + (this.viewJob.disposeLocationDetails.extras ? parseFloat(this.viewJob.disposeLocationDetails.extras.driverCost).toFixed(
                2
              ) : 0)
            : "";
          this.viewJob.disposeLocationDetails.disposalDistace = this.viewJob
            .disposeLocationDetails.disposalDistace
            ? parseFloat(
                this.viewJob.disposeLocationDetails.disposalDistace
              ).toFixed(2) + (this.viewJob.disposeLocationDetails.extras ? parseFloat(this.viewJob.disposeLocationDetails.extras.disposalDistace).toFixed(
                2
              ) : 0)
            : "";
          this.viewJob.disposeLocationDetails.finalDistace = this.viewJob
            .disposeLocationDetails.finalDistace
            ? parseFloat(
                this.viewJob.disposeLocationDetails.finalDistace
              ).toFixed(2) + (this.viewJob.disposeLocationDetails.extras ? parseFloat(this.viewJob.disposeLocationDetails.extras.finalDistace).toFixed(
                2
              ) : 0)
            : "";
          this.viewJob.disposeLocationDetails.travelDistance = this.viewJob
            .disposeLocationDetails.travelDistance
            ? parseFloat(
                this.viewJob.disposeLocationDetails.travelDistance
              ).toFixed(2) + (this.viewJob.disposeLocationDetails.extras ? parseFloat(this.viewJob.disposeLocationDetails.extras.travelDistance).toFixed(
                2
              ) : 0)
            : "";

          if (this.viewJob.disposeLocationDetails.metalRebateCost > 0) {
            this.viewJob.disposeLocationDetails.metal_rebate = "Yes";
          } else {
            this.viewJob.disposeLocationDetails.metal_rebate = "No";
          }
        }
      }
    }
  }
  viewMore(event) {
    this.closeEvents.emit(false);
  }
  closeEvent(event) {
    // var element = document.getElementById("styleSelector");
    //   element.classList.remove("animate-block");
    //     element.classList.add("slide-out");
    this.slide = false;
    this.cdr.detectChanges();
    setTimeout((e) => {
      this.closeEvents.emit(true);
    }, 1000);
    //  this.closeEvents.emit(true);
  }
  openChat() {
    this.openChatWindow = true;
  }
}
