import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ExtrasService } from '../../extras/extras.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService, DialogService, DynamicDialogConfig, DynamicDialogRef, MessageService } from 'primeng';
import { DisposalSitesService } from '../../disposal-sites/disposal-sites.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomersService } from '../customers.service';
import { JobService } from '../../job/job-data.service';

@Component({
  selector: 'app-central-location-extras',
  templateUrl: './central-location-extras.component.html',
  styleUrls: ['./central-location-extras.component.scss']
})
export class CentralLocationExtrasComponent implements OnInit {


  isUpdate = false;
  isView = false;
  data
  extra
  id
  name
  isAcumen: any;
  companyID: any;
  showAdd = false;
  cols = [
    {
      header: "Name",
      field: "extraType",
    },
    {
      header: "Quantity",
      field: "quantity",
    },
   
    {
      header: "Originating Job",
      field: "parent_job_id",
    }
  ];
  filtredList=[];
  currentSlectedExtras=[];
  extrasData: any;
  showLoader = true;
  selectedExtras: any;
  extrasList = [];
  searchArr = [];
  constructor(private service: DisposalSitesService,
    private translateService: TranslateService,
    private cdr: ChangeDetectorRef,
    public dialogService: DialogService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private disposalService: DisposalSitesService,
    private messageService: MessageService,
    private route:ActivatedRoute,
    private confirmDialogService: ConfirmationService,
    private jobService: JobService,   private router: Router) { 
    
    }
  ngOnInit(): void {
   this.id = this.route.snapshot.paramMap.get("id");
   this.name  = this.route.snapshot.paramMap.get("name");
  this.getDisposalDetails(this.id);
  }

  closeEvent(event) {
    history.back();
  }

  checkPermissions(type, module) {
    return this.jobService.checkPermissions(type, module);
  }

  createExtra(event) {
    if (this.showAdd == true) {
      this.showAdd = false;
      this.isView = false;
      this.extrasData = {};
      setTimeout((e) => {
        this.showAdd = true;
      }, 1000);
    } else {
      this.showAdd = true;
    }
    this.cdr.detectChanges();
  }

  



  reloadList(event) { 
    this.showLoader = true;
    this.getDisposalDetails(this.id)
  
  }

  callList(event) {
    this.showAdd = false;
    this.isView = false;
    this.extrasData = {};
    this.cdr.detectChanges();
    this.getDisposalDetails(this.extra);
  }

  getDisposalDetails(id) {
    this.service.getDisposalDetails(id).subscribe((data: any) => {
      this.filtredList= [];
      this.showLoader = false;
      this.data = data.data;
      this.data.extras.forEach(element => {  
        if (element['0']) {
          const extraData = element['0'];
          console.log('Breakdown array:', extraData.breakdown);
          
          extraData.breakdown.forEach(el => {
            
            this.filtredList.push({
              ...el,
              parent_job_id: element.parent_job_id
            });
          });
        }
      });
    });
   
   console.log(this.filtredList)
  }
 viewnMainJob(id){
  this.router.navigate(["job/data/"+id]);
 }
 
}
