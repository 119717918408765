import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ConfirmationService, DialogService, DynamicDialogConfig, DynamicDialogRef, MessageService } from 'primeng';
import { DisposalSitesService } from '../../disposal-sites/disposal-sites.service';
import { ActivatedRoute, Router } from '@angular/router';
import { JobService } from '../../job/job-data.service';
import { TranslateService } from '@ngx-translate/core';
import { CustomersService } from '../customers.service';

@Component({
  selector: 'app-central-locations',
  templateUrl: './central-locations.component.html',
  styleUrls: ['./central-locations.component.scss']
})
export class CentralLocationsComponent implements OnInit {

 isUpdate = false;
   isView = false;
   data
   id
   isAcumen: any;
   companyID: any;
   showAdd = false;
   cols = [
     {
       header: "Site Name",
       field: "Name",
     },
     {
       header: "Site Address",
       field: "disposalSiteAdds",
     },
   ];
   central_location_list=[];
   selected_location: any;
   extrasData: any;
   showLoader = true;
   searchArr = [];
   companyName: any;
   constructor(
      private service: CustomersService,
     private translateService: TranslateService,
     private cdr: ChangeDetectorRef,
     public dialogService: DialogService,
     public ref: DynamicDialogRef,
     public config: DynamicDialogConfig,
     private disposalService: DisposalSitesService,
     private messageService: MessageService,
     private route:ActivatedRoute,
     private confirmDialogService: ConfirmationService,
     private jobService: JobService,   private router: Router,) { }
   
 
   ngOnInit(): void {
     this.id = this.route.snapshot.paramMap.get("id");
     this.companyName = this.route.snapshot.paramMap.get("name");
    
     this.getDisposalDetails();
    
   }
 
   closeEvent(event) {
     history.back();
   }
 
   checkPermissions(type, module) {
     return this.jobService.checkPermissions(type, module);
   }
 

   reloadList(event) { 
     this.showLoader = true;
     this.getDisposalDetails();
   }
 
   callList(event) {
     this.showAdd = false;
     this.isView = false;
     this.extrasData = {};
     this.cdr.detectChanges();
     this.getDisposalDetails();
   }
   
  
   getDisposalDetails() {
    
     this.service.getCentralLocations(this.id).subscribe((data: any) => {
       this.showLoader = false;
       this.data = data.data;
       this.central_location_list =  this.data;
      // this.fetchExtrasList();
 
     });
   }

   viewExtras(data,extra) {
    this.router.navigate(['customers/extras', { id: extra._id, name: extra.Name}]);
   }
 
}
