import {Injectable} from '@angular/core';
export interface NavigationItem {
  id: string;
  title: string;
  type: 'item' | 'collapse' | 'group';
  translate?: string;
  icon?: string;
  hidden?: boolean;
  url?: string;
  classes?: string;
  exactMatch?: boolean;
  external?: boolean;
  target?: boolean;
  breadcrumbs?: boolean;
  function?: any;
  badge?: {
    title?: string;
    type?: string;
  };
  children?: Navigation[];
}

export interface Navigation extends NavigationItem {
  children?: NavigationItem[];
}

const NavigationItems = [
  {
    id: 'navigation',
    type: 'group',
    icon: 'icon-monitor',
    children: []
  }
 
 ];

@Injectable()
export class NavigationItem {
  constructor(){}
  public get() {
    let accessModules = JSON.parse(localStorage.getItem('accessModule'));
    let availableModules = JSON.parse(localStorage.getItem('available_modules'));
    let isAcumen = JSON.parse(localStorage.getItem('isAcumen'));
    let level = JSON.parse(localStorage.getItem('level'));
    // only keep the modules 

    availableModules =  !isAcumen && level == 20 ? availableModules.filter(module => 
      accessModules.some(accessModule => accessModule._id === module._id && (module.moduleName == 'Staff'))
     ) : availableModules.filter(module => 
        accessModules.some(accessModule => accessModule._id === module._id )
    );
   
    NavigationItems[0].children = availableModules;
    return NavigationItems;
  }
}


