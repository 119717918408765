<div class="row">
    <!-- tabs card start -->
    <div class="col-sm-12">
      <app-card moduleName="central-location" module="central-location" 
        cardClass="table-card" blockClass="p-0" >
        <div class="row" style="padding: 20px;">
            <div class="ui-g-11">
              <h1 *ngIf="data">Central locations for : {{ companyName }}</h1>
            </div>
            <div class="ui-g-1">
              <div
                (click)="closeEvent($event)"
                pTooltip="Click to close"
                tooltipPosition="top"
              >
                <i class="fa fa-window-close fa-2x"></i>
              </div>
            </div>
          </div>
        <!-- <perfect-scrollbar [style.max-height]="'415px'"> -->
            <button
            type="button"
            class="btn btn-primary"
            (click)="reloadList(closeEvent)"
            pTooltip="Click to reload the list"
            tooltipPosition="top"
          >
           <i class="fa-solid fa-rotate-right"></i> RELOAD
          </button>
        <p-table #dt [value]="central_location_list" [(selection)]="selected_location" dataKey="_id"
          styleClass="ui-table-customers" [rowHover]="true" [rows]="10" [showCurrentPageReport]="true"
          [rowsPerPageOptions]="[10, 25, 50, 100]" [paginator]="true"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [filterDelay]="0"
          [globalFilterFields]="searchArr">
          <ng-template pTemplate="caption">
            <div class="ui-table-globalfilter-container">
              <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                placeholder="Global Search" />
            </div>
          </ng-template>
          <ng-template pTemplate="header">
            <tr>
              <th *ngFor="let col of cols" [pSortableColumn]="col.field">
                <b>{{ col.header }}</b>
                <p-sortIcon [field]="col.field"></p-sortIcon>
              </th>
  
              <th> <b>Number of extras</b></th>
              <th></th>
            </tr>
            <tr>
              <th *ngFor="let col of cols" [ngSwitch]="col.field">
                <input pInputText type="text" class="ui-inputtext"
                  (input)="dt.filter($event.target.value, col.field, 'contains')" />
              </th>
             
              <!-- <th></th> -->
              <th>
             
              </th>
              <th></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-extra>
            <tr *ngIf="!showLoader">
              <td *ngFor="let col of cols" >
                {{ extra[col.field] }}
              </td>
              <td>
                {{ extra.extras.length }}
              </td>
              <td>
               <span *ngIf=" extra.extras.length"><a href="javascript:void(0);" (click)="viewExtras($event, extra)"> View Extras</a></span>
              </td>
  
            </tr>
          </ng-template>
          <ng-template class="noRecords" *ngIf="central_location_list.length == 0" pTemplate="emptymessage">
            <tr class="noRecords" *ngIf="!showLoader">
              <td [attr.colspan]="cols.length + 2">
                <!-- <div class="my-center-text"> -->
                No Records to display
                <!-- </div> -->
              </td>
            </tr>
          </ng-template>
        </p-table>
        <!-- </perfect-scrollbar> -->
        <div class="loader" *ngIf="showLoader"></div>
      </app-card>
    </div>
    <!-- tabs card end -->
  </div>
  
 
