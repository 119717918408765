<div class="row">
  <!-- tabs card start -->
  <div class="col-sm-12">
    <app-card moduleName="Customers" module="Customer" (addVehicle)="createCustomer($event)" cardClass="table-card"
      blockClass="p-0">
      <!-- <perfect-scrollbar [style.max-height]="'415px'"> -->

      <p-table #dt [value]="customerList" [(selection)]="selectedCustomer" dataKey="_id" styleClass="ui-table-customers"
        [rowHover]="true" [rows]="10" [showCurrentPageReport]="true" [rowsPerPageOptions]="[10, 25, 50, 100]"
        [paginator]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        [filterDelay]="0" [globalFilterFields]="searchArr" styleClass="customer-table ui-table-customers">
        <ng-template pTemplate="caption">
          <div class="ui-table-globalfilter-container">
            <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
              placeholder="Global Search" />
          </div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th *ngFor="let col of cols" [pSortableColumn]="col.field">
              <b>{{ col.header }}</b>
              <p-sortIcon [field]="col.field"></p-sortIcon>
            </th>
            <th></th>
            <th></th>
          </tr>
          <tr>
            <th *ngFor="let col of cols" [ngSwitch]="col.field">
              <input pInputText type="text" class="ui-inputtext"
                (input)="dt.filter($event.target.value, col.field, 'contains')" />
            </th>
            <th></th>
            <th></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-car>
          <tr *ngIf="!showLoader">
            <td *ngFor="let col of cols" (click)="viewCustomer($event, car)">
              {{ car[col.field] }}
            </td>
            <td class="">
              <span (click)="listDisposal($event, car)" class="mob_icon style-toggler">
                <a href="javascript:void(0);"> Disposal Site </a>
              </span>
              <span (click)="listCategory($event, car)" class="mob_icon style-toggler delete_icon">
                <a href="javascript:void(0);">Waste Category</a>
              </span>
              <span (click)="listCentralLocations($event, car)" class="mob_icon style-toggler delete_icon">
                <a href="javascript:void(0);">Central Sites</a>
              </span>
            </td>
            <td class="actions-column"> 
              <span (click)="viewCustomer($event, car)" pTooltip="Click to view a Customer" tooltipPosition="top" class="mob_icon style-toggler">
                <i class="fa fa-eye fa-2x"></i>
              </span>
              <span (click)="editCustomer($event, car)" pTooltip="Click to edit a Customer" tooltipPosition="top" class="mob_icon style-toggler delete_icon" *ngIf="checkPermissions('edit', 'Customers')">
                <i class="fa fa-edit fa-2x"></i>
              </span>
              <span (click)="deleteCustomer(car._id)" pTooltip="Click to delete a Customer" tooltipPosition="top" class="style-toggler mob_icon delete_icon" *ngIf="checkPermissions('delete', 'Customers')">
                <i class="fa fa-trash fa-2x"></i>
              </span>
            </td>
          </tr>
        </ng-template>
        <ng-template class="noRecords" *ngIf="customerList.length == 0" pTemplate="emptymessage">
          <tr class="noRecords" *ngIf="!showLoader">
            <td [attr.colspan]="cols.length + 2">
              <!-- <div class="my-center-text"> -->
              No Records to display
              <!-- </div> -->
            </td>
          </tr>
        </ng-template>
      </p-table>
      <!-- </perfect-scrollbar> -->
      <div class="loader" *ngIf="showLoader"></div>
    </app-card>
  </div>
  <!-- tabs card end -->
</div>

<div *ngIf="showAdd">
  <app-add-edit-customers [isView]="isView" [data]="customerData"
    (closeEvents)="callList($event)"></app-add-edit-customers>
</div>
<div *ngIf="showLink">
  <app-link-disposal-category [isDisposal]="isDisposal" [data]="linkData" [isCategory]="isCategory"
    (closeEvents)="closeAll($event)"></app-link-disposal-category>
</div>