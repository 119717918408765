<div class="">
    <div
      id="styleSelector"
      class="style-block staff-container  open {{
        slide ? 'animate-block' : 'slide-out'
      }}"
    >
      <div class="container h-100">
        <div class="row align-items-center">
          <div class="{{ isView ? 'col-12' : 'col-10' }} mx-auto user-form ">
            <div class="row add-edit-header" *ngIf="!isView">
              <div class="ui-g-10">
                <h4 *ngIf="!isUpdate">Create an Extra </h4>
                <h4 *ngIf="isUpdate">Edit an Extra</h4>
              </div>
              <div
                class="ui-g-2"
                (click)="closeEvent($event)"
                pTooltip="Click to close "
                tooltipPosition="top"
              >
                <i class="fa fa-window-close fa-2x"></i>
              </div>
            </div>
            <div class="">
              <form [formGroup]="extrasForm" *ngIf="!isView">
                <div class="row form-group">
                  <div class="ui-g-12 mt-3">
                    <label for="firstname">Name</label>
                    <input
                      class="ui-g-12"
                      id="firstname"
                      class="form-control"
                      type="text"
                      size="30"
                      formControlName="name"
                      pInputText
                    />
                  </div>
                </div>
                <div class="row form-group">
                  <div class="ui-g-12 mt-3">
                    <label for="qty">Minimum Quantity</label>
                    <input
                      class="ui-g-12"
                      id="qty"
                      type="number"
                      class="form-control"
                      size="30"
                      formControlName="defaultQuantity"
                      pInputText
                    />
                  </div>
                </div>
                <div class="row form-group">
                  <div class="ui-g-12 mt-3">
                    <label for="cost">Cost</label>
                    <input
                      class="ui-g-12"
                      id="cost"
                      type="number"
                      class="form-control"
                      size="30"
                      formControlName="defaultCost"
                      pInputText
                    />
                  </div>
                </div>
                <div class="row form-group">
                  <div class="ui-g-12 mt-3">
                    <label for="cost">Dsposal Name</label>
                    <input
                      class="ui-g-12"
                      id="cost"
                      type="text"
                      class="form-control"
                     
                      formControlName="dsposalName"
                      pInputText
                    />
                  </div>
                </div>
                <div class="row form-group">
                  <div class="ui-g-12 mt-3">
                    <label for="cost">Extra Id</label>
                    <input
                      class="ui-g-12"
                      id="cost"
                      type="text"
                      class="form-control"
                     
                      formControlName="externalId"
                      pInputText
                    />
                  </div>
                </div>
  
              
  
                <div class="row button-cls" *ngIf="!(isView || isUpdate)">
                  <div class="ui-g-12">
                    <p-button
                      label="Create"
                      [disabled]="!extrasForm.valid"
                      (onClick)="createExtras($event)"
                    ></p-button>
                  </div>
                </div>
                <div class="row button-cls" *ngIf="isUpdate">
                  <div class="ui-g-12">
                    <p-button
                      label="Update"
                      [disabled]="!extrasForm.valid"
                      (onClick)="updateExtra($event)"
                    ></p-button>
                  </div>
                </div>
              </form>
              <div *ngIf="isView">
                <div class="row details-header">
                  <div class="ui-g-10">
                    <h4>View Extras</h4>
                  </div>
                  <div
                    class="ui-g-1"
                    (click)="editEvent($event)"
                    pTooltip="Click to edit an extra"
                    tooltipPosition="top"
                    *ngIf="checkPermissions('edit', 'Extras')"
                  >
                    <i class="fa fa-edit fa-2x"></i>
                  </div>
                  <div
                    class="ui-g-1"
                    (click)="closeEvent($event)"
                    pTooltip="Click to close"
                    tooltipPosition="top"
                  >
                    <i class="fa fa-window-close fa-2x"></i>
                  </div>
                </div>
                <div class="details-content">
                  <div class="row">
                    <div class="ui-g-6">
                      <span for="name">Name</span>
                    </div>
                    <div class="ui-g-6">
                      <span for="name">{{ viewData.name }}</span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="ui-g-6">
                      <span for="qty">Quantity</span>
                    </div>
                    <div class="ui-g-6">
                      <span for="qty">{{ viewData.defaultQuantity }}</span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="ui-g-6">
                      <span for="cost">Cost</span>
                    </div>
                    <div class="ui-g-6">
                      <span for="cost">{{ viewData.defaultCost }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  