import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DialogService, DynamicDialogConfig, DynamicDialogRef, MessageService } from 'primeng';
import { VehicleTypesService } from '../../vehicle-types/vehicle-types.service';
import { TranslateService } from '@ngx-translate/core';
import { JobService } from '../../job/job-data.service';
import { ExtrasService } from '../extras.service';

@Component({
  selector: 'app-add-exit-extras',
  templateUrl: './add-exit-extras.component.html',
  styleUrls: ['./add-exit-extras.component.scss']
})
export class AddExitExtrasComponent implements OnInit {

  extrasForm: FormGroup;
  editForm: FormGroup;
  isUpdate = false;
  slide = true;
  isEdit = false;
  isAcumen: any;
  companyID: any;
  companyList = [{ label: "Please Select", value: "" }];
  data: any = [];
  @Input("data") viewData: any = {};
  @Input("isView") isView = false;
  @Output("closeEvents") closeEvents = new EventEmitter<any>();
  constructor( private messageService: MessageService,
    private dialogService: DialogService,
    private service: ExtrasService,
    private cdr: ChangeDetectorRef,
    private translateService: TranslateService,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private jobService: JobService) { 

      this.extrasForm = new FormGroup({
        name: new FormControl("",[Validators.required]),
        defaultQuantity: new FormControl(1, [Validators.required]),
        defaultCost: new FormControl(1, [Validators.required]),
        dsposalName: new FormControl("", [Validators.required]),
        externalId : new FormControl("", [Validators.required]),
      });

    }
 

    ngAfterViewInit() {
      console.log("before ngafterviewinit", this.extrasForm);
  
      console.log("ngafterviewinit", this.extrasForm);
    }
    editEvent(event) {
      this.isEdit = true;
      this.isUpdate = true;
      this.isView = false;
      this.loadData();
    }
    
    
  
  
    createExtras(e) {
      let value: any = "";
      console.log("Value", this.extrasForm.value);
      value = this.extrasForm.value;
  
      this.service.addExtras(value).subscribe(
        (data) => {
          if (data) {
            this.messageService.add({
              severity: "success",
              summary: "Extra created",
              detail: "New extra created successfully",
            });
            // this.ref.close(this);
            // this.closeEvents.emit(true);
          } else if (data.status === 500) {
            this.messageService.add({
              severity: "error",
              summary: "Create Extra",
              detail: data.error.message.errmsg,
            });
          } else {
            this.messageService.add({
              severity: "error",
              summary: "Create Extra",
              detail: "Unable to create a new Extra. Please try again.",
              //  detail: 'Failed to create the User'
            });
          }
          this.closeEvent(true);
        },
        //  });
        (err) => {
          console.log("Error", err);
          this.messageService.add({
            severity: "error",
            summary: "Create Extra",
            // detail: 'Failed to create the Waste types'
            detail: "Unable to create a new Extra. Please try again.",
          });
          // this.dialogService.closeAll();
        }
      );
     
    }
    updateExtra(event: any) {
      // console.log('update student', value.email, value.cemail);
      let value: any = "";
      value = this.extrasForm.value;
      value._id = this.data._id;
      console.log("Update value", this.extrasForm.value, value);
  
      this.service.updateExtras(value).subscribe((data) => {
        console.log(data);
  
        if (data.status === 200) {
          this.messageService.add({
            severity: "success",
            summary: "Update Extra ",
            detail: "Extra updated successfully",
          });
          // this.ref.close(this);
          if (this.isUpdate == true) {
                
                this.closeEvent(true);
              
          } else {
            // this.closeEvents.emit(true);
            this.closeEvent(true);
          }
        } else if (data.status === 500) {
          this.messageService.add({
            severity: "error",
            summary: "Update Extra",
            detail: data.error.message.errmsg,
          });
        } else {
          this.messageService.add({
            severity: "error",
            summary: "Update Extra",
            detail: "Unable to update Extra.Please try again.",
            //  detail: 'Failed to update the user details'
          });
        }
      });
      // } else {
      //     this.messageService.add({
      //         severity: 'failure',
      //         summary: 'Create Student',
      //         detail: 'Student Email and confirm email are not matching'
      //     });
      // }
    }
    ngOnInit() {
   
      this.companyID = localStorage.getItem("companyID");
      this.isAcumen = localStorage.getItem("isAcumen");
      this.loadData();
    }
    loadData() {
      if (this.viewData && Object.keys(this.viewData).length !== 0) {
        console.log(" true vehicleData", this.viewData);
        this.data = this.viewData;
  
        this.isUpdate = true;
        if (this.isUpdate) {
          this.extrasForm.controls["name"].setValue(this.data.name);
          this.extrasForm.controls["defaultQuantity"].setValue(this.data.defaultQuantity);
          this.extrasForm.controls["defaultCost"].setValue(this.data.defaultCost);
          this.extrasForm.controls["dsposalName"].setValue(this.data.dsposalName);
          this.extrasForm.controls["externalId"].setValue(this.data.externalId);
        }
      }
    }
    closeEvent(event) {
      if (this.isEdit == false) {
        //     var element = document.getElementById("styleSelector");
        //   element.classList.remove("animate-block");
        //     element.classList.add("slide-out");
        this.slide = false;
        this.cdr.detectChanges();
        setTimeout((e) => {
          this.closeEvents.emit(true);
        }, 1000);
      } else {
        this.isEdit = false;
        this.isView = true;
        this.isUpdate = false;
        this.loadData();
      }
    }
  
    checkPermissions(type, module) {
      return this.jobService.checkPermissions(type, module);
    }
}
    