import { ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy } from '@angular/router';

export class CustomRouteReuseStrategy implements RouteReuseStrategy {
  private routeCache = new Map<string, DetachedRouteHandle>();

  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    // Determine if this route should be cached
    // You can customize this based on route data or other conditions
    return true;
  }

  store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
    const routeKey = this.getRouteKey(route);
    if (routeKey) {
      this.routeCache.set(routeKey, handle);
    }
  }

  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    const routeKey = this.getRouteKey(route);
    return !!routeKey && !!this.routeCache.get(routeKey);
  }

  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
    const routeKey = this.getRouteKey(route);
    return routeKey ? this.routeCache.get(routeKey) || null : null;
  }

  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    // Compare the route configurations to determine if they're the same route
    console.log('future', future.routeConfig);
    console.log('curr', curr.routeConfig);
    return future.routeConfig === curr.routeConfig;
  }

  private getRouteKey(route: ActivatedRouteSnapshot): string | null {
    // Create a unique key for the route including path, parameters, and query parameters
    const segments = route.pathFromRoot
      .filter(r => r.routeConfig)
      .map(r => r.routeConfig!.path);

    const params = route.params;
    const paramString = Object.keys(params).map(key => `${key}=${params[key]}`).join('&');

    const queryParams = route.queryParams;
    const queryParamString = Object.keys(queryParams).map(key => `${key}=${queryParams[key]}`).join('&');

    return `${segments.join('/')}?${paramString}&${queryParamString}`;
  }
}